.background {
  margin: 15px 60px 10px;
  width: calc(100% - 120px);
  line-height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.background h3 {
  margin: 15px;
  color: #00cff4;
}

.background a {
  text-decoration: none;
  margin: 15px;
  font-weight: bold;
  color: var(--color-secondary);
}

.background p:hover {
  cursor: pointer;
  opacity: 0.8;
}

.container {
  display: flex;
  height: max-content;
  position: relative;
}

.icon {
  width: 40px;
  height: 60px;
  display: flex;
  transition: all 50ms ease-in-out;
  z-index: 1;
  margin: auto;
  position: absolute;
}

.icon svg {
  margin: auto;
}

.iconLeft {
  left: 15px;
  top: calc(50% - 40px);
  color: #fe3e0d;
}

.iconRight {
  right: 15px;
  top: calc(50% - 40px);
  color: #00cff4;
}

.iconLeft:hover {
  color: white;
  background-color: #fe3e0d;
  cursor: pointer;
}

.iconRight:hover {
  color: white;
  background-color: #00cff4;
  cursor: pointer;
}

.list {
  padding: 10px 54px;
  position: relative;
  scroll-behavior: smooth;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}

.list::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 768px) {
  .background {
    margin: 20px 20px 10px;
    width: calc(100% - 40px);
  }
  .icon {
    display: none;
  }
  .list {
    padding: 0 14px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .list::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .background {
    margin: 10px 10px;
    margin-top: 10px;
    width: calc(100% - 20px);
  }

  .list {
    padding: 0 7px;
  }
}
