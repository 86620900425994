.background {
  margin: 15px 60px;
  width: calc(100% - 120px);
  text-align: center;
}

.background h2 {
  margin: 10px;
}

.container {
  margin: 25px 60px;
  width: calc(100% - 120px);
}

.list {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, 18%);
  gap: 15px;
}

.list div {
  width: calc(100% - 0px) !important;
  margin: 0px !important;
}

.list div a div { 
  width: calc(100% - 20px) !important;
}

@media only screen and (max-width: 768px) {
  .background {
    margin: 20px 20px 15px;
    width: calc(100% - 40px);
  }

  .container {
    margin: 15px 20px;
    width: calc(100% - 40px);
  }

  .list {
    grid-template-columns: repeat(auto-fill, 20%);
  }
}

@media only screen and (max-width: 480px) {
  .background {
    margin: 10px 10px;
    width: calc(100% - 20px);
  }

  .container {
    margin: 12px 10px;
    width: calc(100% - 20px);
  }

  .list {
    grid-template-columns: repeat(auto-fill, 30%);
  }
}
