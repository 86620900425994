.background {
  display: inline-block;
  width: 18%;
  aspect-ratio: 3/4;
  border-radius: 10px;
  margin: 6px;
  overflow: hidden;
  position: relative;
  transition: all 50ms ease-in-out;
}

.background:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.about {
  background-color: black;
  opacity: 0.9;
  display: none !important;
}

.info {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 10px;
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.infoTitle {
  color: #00cff4;
  margin: 0px 0px;
  font-weight: 700;
  font-weight: bold;
  white-space: pre-wrap;
  font-size: 90%;
}

.infoAbout {
  width: 100%;
  color: #fe3e0d;
  margin: 5px 0px;
  display: block;
  font-size: 75%;
  align-items: center;
}

.infoAbout span:last-child {
  float: right;
}

.infoDescription {
  color: white;
  margin: 0;
  font-size: 70%;
  display: -webkit-box;
  white-space: break-spaces;
  max-height: 4rem;
  line-height: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

/* .infoDescription {
  width: calc(100% - 30px);
  height: calc(40% - 15px);
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  font-size: 80%;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
} */

.background:hover .about {
  display: flex !important;
}

@media only screen and (max-width: 768px) {
  .background {
    width: 20%;
  }

  .background:hover {
    transform: scale(1);
  }

  .background:hover .about {
    display: none !important;
  }

  .infoAbout {
    font-size: 60%;
  }

  .infoDescription {
    font-size: 55%;
    max-height: 3.5rem;
    line-height: 0.7rem;
  }
}

@media only screen and (max-width: 480px) {
  .background {
    width: 30%;
    margin: 3px;
  }

}

