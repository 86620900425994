.container {
  height: 160.3125px;
  flex-basis: 285px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 10px;
  border-radius: 10;
  position: relative;
  overflow: hidden;
}

.container:hover {
  cursor: pointer;
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  z-index: -1;
}

.iconBG {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  background-color: white;
}

.icon {
  position: absolute;
  top: calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  color: red;
}

.gradient {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  background: linear-gradient(transparent, black);
}

.name {
  margin: 0;
  padding: 10px;
  font-weight: bold;
}

.player,
.player iframe {
  width: 100%;
  height: 100%;
}