.background {
  flex-basis: 160px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.image {
  width: 160px;
  height: 200px;
  object-fit: cover;
}

.svg {
  width: 160px;
  height: 200px;
  display: flex;
}

.name {
  font-weight: bold;
  margin: 10px;
  margin-bottom: 5px;
  color: var(--color-primary);
}

.character {
  font-size: 0.8rem;
  margin: 10px 10px;
  margin-top: 0;
  color: var(--color-secondary);
}

@media only screen and (max-width: 800px) {
  .background {
    flex-basis: 125px;
  }

  .image, .svg {
    width: 125px;
    height: 160px;
  }
}
