.main {
  /* height: calc(100% - 60px); */
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin: 5px 0;
  margin-top: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.value {
  margin: 5px 0;
  margin-bottom: 8px;
}

@media only screen and (max-width: 800px) {
  .main {
    padding: 20px;
  }
}
