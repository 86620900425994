.main {
  display: flex;
  width: 100%;
  position: relative;
}

.backdrop {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 250px);
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0.7;
  z-index: -2;
}

.overlay {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(90deg, var(--color-dark) 250px, transparent);
  z-index: -1;
}

.posterContainer {
  width: 275px;
  padding: 25px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.poster {
  width: 275px;
  aspect-ratio: 3/4;
  object-fit: cover;
  border-radius: 10px;
}

.info {
  flex: 1;
  height: 100%;
  padding: 30px;
  text-shadow: 0 0 10px black;
}

.title {
  margin: 0;
  margin-top: 20px;
  font-size: 2.2rem;
  font-weight: 768;
}

.details {
  margin: 5px;
  margin-left: 0;
  font-weight: 200;
}

.genres::before,
.runtime::before {
  content: "\2022";
  margin: 8px;
}

.rating {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 15px;
  margin: 20px 0px;
  /* gap: 10px; */
  border-radius: 10px;
  font-weight: bold;
  text-shadow: none;
  color: var(--color-dark);
  background-color: var(--color-primary);
}

.progress {
  border-radius: 45px;
  width: 60px;
  height: 60px;
  padding: 5px;
  margin: 10px 10px;
  font-weight: bold;
}

.progress text {
  font-size: 2rem;
}

.progress tspan {
  height: 100%;
  font-size: 1.2rem;
}
.rating :nth-child(4) {
  padding: 10px;
  margin: 10px;
  border-radius: 360px;
  color: var(--color-primary);
  background-color: var(--color-dark);
}

.tagline {
  font-style: italic;
  opacity: 0.7;
}
.overviewH {
  font-size: 1.2rem;
  font-weight: 768;
  margin: 0;
}

.overviewT {
  font-size: 1rem;
}

.credits {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 20px;
}

.credit {
  width: fit-content;
  height: fit-content;
}

.creditTitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 2px;
}
.creditSubtitle {
  font-size: 0.9rem;
  margin: 2px;
}

@media only screen and (max-width: 800px) {
  .main {
    width: calc(100% - 40px);
    padding: 20px;
  }

  .posterContainer {
    width: 50%;
    margin: auto;
    padding: 20px;
  }

  .poster {
    width: 200px;
  }

  .overlay {
    display: none;
  }

  .backdrop {
    width: 100%;
    opacity: 0.3;
    border-radius: 10px;
  }

  .info {
    padding: 20px;
  }

  .title {
    font-size: 2rem;
  }

  .details {
    font-weight: 300;
  }

  .credits {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    gap: 20px;
  }
}
