:root {
  /* --color-primary: #00f48e;
  --color-dark: #002214; */
  --color-primary: #00cff4;
  --color-dark: #001e24;
  --color-secondary: #fe3e0d;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Source Sans Pro", Arial, sans-serif;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  color: white;
}

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

#root {
  padding-bottom: 30px;
  scroll-behavior: smooth;
}

#background {
  background-image: url("./images/bg7.jpg");
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

body {
  background-color: black;
}

.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: #1f1f47;
}
.blob {
  position: fixed;
  width: 50%;
  height: 75%;
  background: linear-gradient(
    180deg,
    rgba(47, 184, 255, 0.42) 31.77%,
    #5c9df1 100%
  );
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
  animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6),
    inset 100px 100px 0 0px #fa709a, inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
}

@-webkit-keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

.bg {
  width: fit-content;
  /* height: fit-content; */
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* background-color: var(--color-dark); */
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading_container {
  width: 100%;
  min-height: 100%;
  position: relative;
}

.loading {
  width: 50px;
  height: 50px;
  border: 10px solid var(--color-dark);
  border-top: 10px solid var(--color-primary);
  border-radius: 50%;
  position: absolute;
  top: calc(50%);
  left: calc(50% - 32px);
  animation: spinner 1.5s linear infinite;
}
